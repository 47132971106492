import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'bulma/css/bulma.min.css';
import './styles/main.scss';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 