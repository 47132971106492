import { WeatherResponse, ForecastResponse } from '../types/weather';
import { API_CONFIG } from '../config/api';

interface WeatherApiConfig {
  apiKey: string;
  baseUrl: string;
}

interface AirQualityResponse {
  list: [{
    main: {
      aqi: number;
    };
    components: {
      co: number;
      no: number;
      no2: number;
      o3: number;
      so2: number;
      pm2_5: number;
      pm10: number;
      nh3: number;
    };
  }];
}

export class WeatherApiService {
  private config: WeatherApiConfig;
  private cache: Map<string, {data: any, timestamp: number}> = new Map();
  private readonly CACHE_DURATION = 1000 * 60 * 15; // 15 minutes

  constructor(apiKey?: string) {
    const configuredKey = apiKey || API_CONFIG.weatherApiKey;
    
    if (!configuredKey) {
      console.error('Weather API key is missing. Please check your environment variables.');
    }

    this.config = {
      apiKey: configuredKey || '',
      baseUrl: API_CONFIG.baseUrl
    };
  }

  private async fetchWithRetry<T>(endpoint: string, retries = 3): Promise<T> {
    for (let i = 0; i < retries; i++) {
      try {
        if (!this.config.apiKey) {
          throw new Error('API key is not configured');
        }

        console.log('Fetching:', endpoint.replace(this.config.apiKey, '[REDACTED]')); // Log URL without API key
        const response = await fetch(endpoint);
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('API Error:', response.status, errorText);
          throw new Error(`Weather API error: ${response.status} - ${errorText}`);
        }
        
        return await response.json();
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i === retries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i)));
      }
    }
    throw new Error('Max retries reached');
  }

  private async fetchFromCacheOrApi<T>(cacheKey: string, endpoint: string, options: { cache: boolean } = { cache: true }): Promise<T> {
    const cached = this.cache.get(cacheKey);
    
    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION && options.cache) {
      return cached.data;
    }

    const data = await this.fetchWithRetry<T>(endpoint);
    this.cache.set(cacheKey, {data, timestamp: Date.now()});
    return data;
  }

  async getCurrentWeather(location: string): Promise<WeatherResponse> {
    return this.fetchFromCacheOrApi<WeatherResponse>(
      `current_${location}`,
      `${this.config.baseUrl}/current.json?key=${this.config.apiKey}&q=${location}&aqi=yes&lang=sq`
    );
  }

  async getForecast(location: string, days: number = 7): Promise<ForecastResponse> {
    return this.fetchFromCacheOrApi<ForecastResponse>(
      `forecast_${location}_${days}`,
      `${this.config.baseUrl}/forecast.json?key=${this.config.apiKey}&q=${location}&days=${days}&lang=sq`
    );
  }

  async getAstronomy(location: string, date: string) {
    return this.fetchFromCacheOrApi(
      `astronomy_${location}_${date}`,
      `${this.config.baseUrl}/astronomy.json?key=${this.config.apiKey}&q=${location}&dt=${date}`
    );
  }

  async getPastWeather(location: string, days: number = 7) {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - days);

    return this.fetchFromCacheOrApi(
      `history_${location}_${days}`,
      `${this.config.baseUrl}/history.json?key=${this.config.apiKey}&q=${location}&dt=${startDate.toISOString().split('T')[0]}&end_dt=${endDate.toISOString().split('T')[0]}&lang=sq`
    );
  }

  async getYrNoForecast(lat: number, lon: number) {
    return this.fetchFromCacheOrApi(
      `yrno_${lat}_${lon}`,
      `https://api.met.no/weatherapi/locationforecast/2.0/complete?lat=${lat}&lon=${lon}`
    );
  }

  async getAirQuality(lat: number, lon: number): Promise<AirQualityResponse> {
    return this.fetchFromCacheOrApi<AirQualityResponse>(
      `airquality_${lat}_${lon}`,
      `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${import.meta.env.VITE_OPENWEATHERMAP_API_KEY}`
    );
  }

  async getOpenWeatherData(lat: number, lon: number) {
    return this.fetchFromCacheOrApi(
      `openweather_${lat}_${lon}`,
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${import.meta.env.VITE_OPENWEATHERMAP_API_KEY}&units=metric&lang=sq`
    );
  }

  async getIPLocation(): Promise<WeatherResponse> {
    try {
      const response = await this.fetchFromCacheOrApi<WeatherResponse>(
        'ip_location',
        `${this.config.baseUrl}/ip.json?key=${this.config.apiKey}&q=auto:ip`,
        { cache: false }
      );
      
      console.log('IP Location Response:', response);
      
      return response;
    } catch (error) {
      console.error('IP Location Error:', error);
      throw error;
    }
  }
} 