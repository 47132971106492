export const drawWeatherEffects = (
  ctx: CanvasRenderingContext2D,
  cloudCover: number,
  width: number,
  height: number,
  isDaytime: boolean
) => {
  // Cloud layer
  if (cloudCover > 0) {
    const cloudGradient = ctx.createLinearGradient(0, 0, 0, height);
    if (isDaytime) {
      cloudGradient.addColorStop(0, `rgba(255, 255, 255, ${cloudCover * 0.3})`);
      cloudGradient.addColorStop(1, `rgba(255, 255, 255, ${cloudCover * 0.1})`);
    } else {
      cloudGradient.addColorStop(0, `rgba(50, 50, 70, ${cloudCover * 0.3})`);
      cloudGradient.addColorStop(1, `rgba(50, 50, 70, ${cloudCover * 0.1})`);
    }
    
    ctx.fillStyle = cloudGradient;
    ctx.fillRect(0, 0, width, height);
  }
}; 