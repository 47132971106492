import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getWeatherIcon } from '../../utils/weatherIcons';

interface WeatherMapProps {
  center: [number, number];
  zoom: number;
  apiKey: string;
}

type LayerType = 'temp' | 'precipitation' | 'wind' | 'clouds' | 'satellite' | 'osm';

interface LayerInfo {
  name: string;
  icon: string;
  description: string;
  urlParam: string;
  legend?: {
    colors: string[];
    labels: string[];
  };
  isBaseLayer?: boolean;
}

type LayerInfoMap = Record<LayerType, LayerInfo>;

// Add interface for city data
interface CityData {
  name: string;
  country: string;
  lat: number;
  lon: number;
  temp_c?: number;
  condition?: {
    text: string;
    code: number;
  };
  priority: number; // Add priority level (1 = highest, 3 = lowest)
}

// Updated major cities list
const majorCities: CityData[] = [
  // Albania (12 cities)
  { name: 'Tiranë', country: 'AL', lat: 41.3275, lon: 19.8187, priority: 1 },
  { name: 'Durrës', country: 'AL', lat: 41.3233, lon: 19.4412, priority: 1 },
  { name: 'Vlorë', country: 'AL', lat: 40.4667, lon: 19.4833, priority: 1 },
  { name: 'Shkodër', country: 'AL', lat: 42.0683, lon: 19.5126, priority: 1 },
  { name: 'Fier', country: 'AL', lat: 40.7239, lon: 19.5567, priority: 2 },
  { name: 'Elbasan', country: 'AL', lat: 41.1125, lon: 20.0822, priority: 2 },
  { name: 'Korçë', country: 'AL', lat: 40.6186, lon: 20.7808, priority: 2 },
  { name: 'Berat', country: 'AL', lat: 40.7058, lon: 19.9522, priority: 2 },
  { name: 'Lushnjë', country: 'AL', lat: 40.9419, lon: 19.7050, priority: 3 },
  { name: 'Kavajë', country: 'AL', lat: 41.1856, lon: 19.5569, priority: 3 },
  { name: 'Pogradec', country: 'AL', lat: 40.9025, lon: 20.6525, priority: 3 },
  { name: 'Sarandë', country: 'AL', lat: 39.8756, lon: 20.0053, priority: 3 },

  // Kosovo (12 cities)
  { name: 'Prishtinë', country: 'XK', lat: 42.6629, lon: 21.1655, priority: 1 },
  { name: 'Prizren', country: 'XK', lat: 42.2139, lon: 20.7397, priority: 1 },
  { name: 'Ferizaj', country: 'XK', lat: 42.3706, lon: 21.1553, priority: 2 },
  { name: 'Pejë', country: 'XK', lat: 42.6591, lon: 20.2883, priority: 2 },
  { name: 'Gjakovë', country: 'XK', lat: 42.3803, lon: 20.4308, priority: 2 },
  { name: 'Gjilan', country: 'XK', lat: 42.4639, lon: 21.4694, priority: 2 },
  { name: 'Mitrovicë', country: 'XK', lat: 42.8914, lon: 20.8661, priority: 2 },
  { name: 'Podujevë', country: 'XK', lat: 42.9113, lon: 21.1932, priority: 3 },
  { name: 'Vushtrri', country: 'XK', lat: 42.8231, lon: 20.9675, priority: 3 },
  { name: 'Suharekë', country: 'XK', lat: 42.3589, lon: 20.8258, priority: 3 },
  { name: 'Rahovec', country: 'XK', lat: 42.3994, lon: 20.6547, priority: 3 },
  { name: 'Drenas', country: 'XK', lat: 42.6242, lon: 20.8989, priority: 3 },

  // Albanian majority cities outside Albania and Kosovo (10 cities)
  { name: 'Tetovë', country: 'MK', lat: 42.0097, lon: 20.9715, priority: 2 },
  { name: 'Gostivar', country: 'MK', lat: 41.7969, lon: 20.9083, priority: 2 },
  { name: 'Shkup', country: 'MK', lat: 42.0024, lon: 21.3937, priority: 2 },
  { name: 'Kumanovë', country: 'MK', lat: 42.1322, lon: 21.7144, priority: 3 },
  { name: 'Strugë', country: 'MK', lat: 41.1775, lon: 20.6789, priority: 3 },
  { name: 'Ulqin', country: 'ME', lat: 41.9294, lon: 19.2244, priority: 2 },
  { name: 'Tuz', country: 'ME', lat: 42.3656, lon: 19.3314, priority: 3 },
  { name: 'Preshevë', country: 'RS', lat: 42.3069, lon: 21.6611, priority: 3 },
  { name: 'Bujanovc', country: 'RS', lat: 42.4606, lon: 21.7669, priority: 3 },
  { name: 'Medvegjë', country: 'RS', lat: 42.8353, lon: 21.5808, priority: 3 },
];

// Add this interface for OpenWeatherMap response
interface OpenWeatherMapResponse {
  main: {
    temp: number;
    humidity: number;
  };
  weather: Array<{
    id: number;
    main: string;
    description: string;
  }>;
  wind: {
    speed: number;
  };
  clouds: {
    all: number;
  };
  rain?: {
    '1h'?: number;
  };
}

// Add this function to get temperature-based color
const getTemperatureColor = (temp: number): string => {
  if (temp <= -40) return '#0d47a1';     // Deep blue
  if (temp <= -30) return '#2196f3';     // Light blue
  if (temp <= -20) return '#90caf9';     // Very light blue
  if (temp <= -10) return '#bbdefb';     // Pale blue
  if (temp <= 0) return '#e3f2fd';       // Almost white blue
  if (temp <= 10) return '#ffecb3';      // Light warm yellow
  if (temp <= 20) return '#ffa726';      // Orange
  if (temp <= 30) return '#f44336';      // Red
  return '#b71c1c';                      // Deep red
};

export const WeatherMap: React.FC<WeatherMapProps> = ({
  center,
  zoom,
  apiKey,
}) => {
  const mapRef = useRef<L.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const cityMarkersRef = useRef<L.Marker[]>([]);
  const [activeLayer, setActiveLayer] = useState<LayerType>('temp');
  const [showLegend, setShowLegend] = useState(true);
  const [baseLayer, setBaseLayer] = useState<'osm' | 'satellite'>('satellite');
  const [showCities, setShowCities] = useState(true);
  const [citiesWeather, setCitiesWeather] = useState<Map<string, CityData>>(new Map());
  const [currentZoom, setCurrentZoom] = useState(zoom);
  const [isLegendVisible, setIsLegendVisible] = useState(true);
  const [isLoadingMarkers, setIsLoadingMarkers] = useState(false);

  const layerInfo: LayerInfoMap = {
    temp: {
      name: 'Temperatura',
      icon: 'fa-thermometer-half',
      description: 'Shfaq temperaturën në hartë',
      urlParam: 'temp_new',
      legend: {
        colors: [
          '#0d47a1', // Deep blue (-40°C)
          '#2196f3', // Light blue (-30°C)
          '#90caf9', // Very light blue (-20°C)
          '#bbdefb', // Pale blue (-10°C)
          '#e3f2fd', // Almost white blue (0°C)
          '#ffecb3', // Light warm yellow (10°C)
          '#ffa726', // Orange (20°C)
          '#f44336', // Red (30°C)
          '#b71c1c'  // Deep red (40°C)
        ],
        labels: ['-40°C', '-30°C', '-20°C', '-10°C', '0°C', '10°C', '20°C', '30°C', '40°C']
      }
    },
    precipitation: {
      name: 'Reshjet',
      icon: 'fa-cloud-rain',
      description: 'Shfaq reshjet në hartë',
      urlParam: 'precipitation_new',
      legend: {
        colors: ['#fff', '#c4ffff', '#0099ff', '#00ff00', '#ffff00', '#ff9900', '#ff0000'],
        labels: ['0', '0.5', '1', '2', '4', '8', '12 mm/h']
      }
    },
    wind: {
      name: 'Era',
      icon: 'fa-wind',
      description: 'Shfaq shpejtësinë e erës',
      urlParam: 'wind_new',
      legend: {
        colors: ['#fff', '#99ffff', '#99ff99', '#ffff99', '#ff9999', '#ff99ff'],
        labels: ['0', '5', '10', '20', '30', '40+ m/s']
      }
    },
    clouds: {
      name: 'Retë',
      icon: 'fa-cloud',
      description: 'Shfaq mbulimin me re',
      urlParam: 'clouds_new',
      legend: {
        colors: ['#fff', '#f2f2f2', '#d9d9d9', '#bfbfbf', '#a6a6a6', '#8c8c8c'],
        labels: ['0%', '20%', '40%', '60%', '80%', '100%']
      }
    },
    satellite: {
      name: 'Satelit',
      icon: 'fa-satellite',
      description: 'Shfaq imazhin satelitor',
      urlParam: 'satellite',
      isBaseLayer: true
    },
    osm: {
      name: 'Standarde',
      icon: 'fa-map',
      description: 'Harta Standarde',
      urlParam: 'osm',
      isBaseLayer: true
    }
  } as const;

  useEffect(() => {
    if (!mapContainerRef.current || mapRef.current) return;

    // Initialize map
    mapRef.current = L.map(mapContainerRef.current).setView(center, zoom);

    // Add zoom change listener
    mapRef.current.on('zoomend', () => {
      if (mapRef.current) {
        setCurrentZoom(mapRef.current.getZoom());
      }
    });

    // Add satellite base layer by default
    L.tileLayer(
      `https://tile.openweathermap.org/map/satellite/{z}/{x}/{y}.png?appid=${apiKey}`,
      {
        opacity: 1,
        attribution: '© OpenWeatherMap'
      }
    ).addTo(mapRef.current);

    // Add semi-transparent roads
    L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        opacity: 0.35,
        attribution: '© OpenStreetMap contributors'
      }
    ).addTo(mapRef.current);

    // Add temperature layer by default
    L.tileLayer(
      `https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=${apiKey}`,
      {
        opacity: 0.6,
        attribution: '© OpenWeatherMap'
      }
    ).addTo(mapRef.current);

    return () => {
      mapRef.current?.remove();
      mapRef.current = null;
    };
  }, []);

  // Add this function to fetch weather from OpenWeatherMap
  const fetchCityWeather = async (lat: number, lon: number, name: string, country: string): Promise<CityData | null> => {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${apiKey}&lang=sq`
      );
      
      if (!response.ok) {
        throw new Error(`Weather API error: ${response.status}`);
      }

      const data: OpenWeatherMapResponse = await response.json();
      
      // Find the city's priority from majorCities array
      const cityInfo = majorCities.find(city => city.name === name) || { priority: 3 };
      
      return {
        name,
        country,
        lat,
        lon,
        temp_c: data.main.temp,
        condition: {
          text: data.weather[0].description,
          code: data.weather[0].id
        },
        priority: cityInfo.priority // Add the priority property
      };
    } catch (error) {
      console.error(`Failed to fetch weather:`, error);
      return null;
    }
  };

  // Update the effect that fetches city weather
  useEffect(() => {
    const fetchCitiesWeather = async () => {
      setIsLoadingMarkers(true);
      const weatherMap = new Map<string, CityData>();

      try {
        for (const city of majorCities) {
          const weatherData = await fetchCityWeather(city.lat, city.lon, city.name, city.country);
          if (weatherData) {
            weatherMap.set(city.name, {
              ...city,
              temp_c: weatherData.temp_c,
              condition: weatherData.condition
            });
          }
        }
        setCitiesWeather(weatherMap);
      } catch (error) {
        console.error('Failed to fetch cities weather:', error);
      } finally {
        setIsLoadingMarkers(false);
      }
    };

    if (showCities) {
      fetchCitiesWeather();
    }
  }, [showCities, apiKey]);

  // Update city markers effect with zoom-based visibility
  useEffect(() => {
    if (!mapRef.current) return;

    cityMarkersRef.current.forEach(marker => marker.remove());
    cityMarkersRef.current = [];

    if (showCities) {
      const visibleCities = majorCities.filter(city => {
        if (currentZoom <= 6) return city.priority === 1;
        if (currentZoom <= 8) return city.priority <= 2;
        return true;
      });

      visibleCities.forEach(async city => {
        try {
          // Fetch weather data first
          const response = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${city.lat}&lon=${city.lon}&units=metric&appid=${apiKey}&lang=sq`
          );
          
          if (!response.ok) return;
          
          const weatherData: OpenWeatherMapResponse = await response.json();
          const cityWeatherData = citiesWeather.get(city.name);
          
          if (!cityWeatherData || !mapRef.current) return;

          const temp = Math.round(cityWeatherData.temp_c || 0);
          const backgroundColor = getTemperatureColor(temp);
          const isDay = new Date().getHours() > 6 && new Date().getHours() < 20;
          const iconUrl = cityWeatherData.condition ? getWeatherIcon(cityWeatherData.condition.code, isDay) : '';

          // Create text label with weather icon
          const labelText = L.divIcon({
            className: 'city-label',
            html: `
              <div class="city-label-content" style="background-color: ${backgroundColor}">
                <div class="label-row">
                  <span class="city-name">${city.name}</span>
                  <span class="weather-info">
                    <img src="${iconUrl}" alt="${cityWeatherData.condition?.text || ''}" />
                    <span class="temperature">${temp}°</span>
                  </span>
                </div>
              </div>
            `,
            iconSize: [120, 24],
            iconAnchor: [60, 12]
          });

          const marker = L.marker([city.lat, city.lon], { 
            icon: labelText,
            interactive: true
          }).bindPopup(`
            <div class="city-popup">
              <h4>${cityWeatherData.name}</h4>
              <div class="weather-details">
                <div class="detail-row">
                  <span class="label">Temperatura:</span>
                  <span class="value">${temp}°C</span>
                </div>
                ${cityWeatherData.condition ? `
                  <div class="detail-row">
                    <span class="label">Kushtet:</span>
                    <span class="value">${cityWeatherData.condition.text}</span>
                  </div>
                ` : ''}
                <div class="detail-row">
                  <span class="label">Lagështia:</span>
                  <span class="value">${weatherData.main.humidity}%</span>
                </div>
                <div class="detail-row">
                  <span class="label">Era:</span>
                  <span class="value">${Math.round(weatherData.wind.speed * 3.6)} km/h</span>
                </div>
                <div class="detail-row">
                  <span class="label">Retë:</span>
                  <span class="value">${weatherData.clouds.all}%</span>
                </div>
                ${weatherData.rain ? `
                  <div class="detail-row">
                    <span class="label">Reshjet:</span>
                    <span class="value">${weatherData.rain['1h'] || 0} mm</span>
                  </div>
                ` : ''}
              </div>
            </div>
          `);

          if (mapRef.current) {
            marker.addTo(mapRef.current);
            cityMarkersRef.current.push(marker);
          }
        } catch (error) {
          console.error(`Failed to fetch weather data for ${city.name}:`, error);
        }
      });
    }
  }, [showCities, citiesWeather, currentZoom, apiKey]);

  const updateWeatherLayer = (layerType: LayerType) => {
    if (!mapRef.current) return;

    // Remove existing layers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.TileLayer) {
        mapRef.current?.removeLayer(layer);
      }
    });

    const layer = layerInfo[layerType];

    if (layer.isBaseLayer) {
      if (layerType === 'satellite') {
        // Use ESRI World Imagery for satellite view
        L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          }
        ).addTo(mapRef.current);

        setBaseLayer('satellite');
      } else {
        // Default OpenStreetMap layer
        L.tileLayer(
          'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            attribution: '© OpenStreetMap contributors'
          }
        ).addTo(mapRef.current);
        setBaseLayer('osm');
      }
    } else {
      // Add base layer first
      if (baseLayer === 'satellite') {
        L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          }
        ).addTo(mapRef.current);
      } else {
        L.tileLayer(
          'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            attribution: '© OpenStreetMap contributors'
          }
        ).addTo(mapRef.current);
      }

      // Add weather layer on top
      L.tileLayer(
        `https://tile.openweathermap.org/map/${layer.urlParam}/{z}/{x}/{y}.png?appid=${apiKey}`,
        {
          opacity: 0.6,
          attribution: '© OpenWeatherMap'
        }
      ).addTo(mapRef.current);
    }

    setActiveLayer(layerType);
  };

  return (
    <div className="card weather-map">
      <div className="card-content">
        {/* Header Section - 10% */}
        <div className="header-section">
          {/* Title Section */}
          <div className="level">
            <div className="level-left">
              <h3 className="title is-4">
                Harta e Motit
              </h3>
            </div>
            {isLoadingMarkers && (
              <div className="level-right">
                <span className="icon is-small has-text-info">
                  <i className="fas fa-circle-notch fa-spin"></i>
                </span>
                <span className="ml-2 has-text-info is-size-7">Duke ngarkuar qytetet...</span>
              </div>
            )}
          </div>

          {/* Map Controls - All in one row */}
          <div className="map-controls">
            <div className="control-container is-flex is-align-items-center is-justify-content-center">
              {/* Base Layer Controls */}
              <div className="field has-addons mr-4">
                <p className="control">
                  <button 
                    className={`button ${baseLayer === 'osm' ? 'is-primary' : ''}`}
                    onClick={() => updateWeatherLayer('osm')}
                  >
                    <span className="icon">
                      <i className="fas fa-map"></i>
                    </span>
                    <span>Standarde</span>
                  </button>
                </p>
                <p className="control">
                  <button 
                    className={`button ${baseLayer === 'satellite' ? 'is-primary' : ''}`}
                    onClick={() => updateWeatherLayer('satellite')}
                  >
                    <span className="icon">
                      <i className="fas fa-satellite"></i>
                    </span>
                    <span>Satelit</span>
                  </button>
                </p>
              </div>

              {/* Weather Layer Controls */}
              <div className="field has-addons mr-4">
                {Object.entries(layerInfo)
                  .filter(([_, info]) => !info.isBaseLayer)
                  .map(([key, info]) => (
                    <p className="control" key={key}>
                      <button 
                        className={`button ${activeLayer === key ? 'is-primary' : ''}`}
                        onClick={() => updateWeatherLayer(key as LayerType)}
                        title={info.description}
                      >
                        <span className="icon">
                          <i className={`fas ${info.icon}`}></i>
                        </span>
                        <span>{info.name}</span>
                      </button>
                    </p>
                  ))}
              </div>

              {/* Toggle Controls */}
              <div className="field is-flex">
                <label className="checkbox mr-4">
                  <input
                    type="checkbox"
                    checked={showLegend}
                    onChange={(e) => setShowLegend(e.target.checked)}
                  />
                  {' '}Legjenda
                </label>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={showCities}
                    onChange={(e) => setShowCities(e.target.checked)}
                  />
                  {' '}Qytetet
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Map Container - 90% */}
        <div className="map-container-wrapper">
          <div 
            ref={mapContainerRef} 
            className="map-container"
          />
          
          {/* Legend */}
          {showLegend && layerInfo[activeLayer]?.legend && (
            <div className="map-legend">
              <div className="legend-header">
                <div className="legend-title">{layerInfo[activeLayer].name}</div>
                <button 
                  className="legend-toggle"
                  onClick={() => setIsLegendVisible(!isLegendVisible)}
                  title={isLegendVisible ? 'Fshih legjendën' : 'Shfaq legjendën'}
                >
                  <i className={`fas fa-chevron-${isLegendVisible ? 'down' : 'up'}`}></i>
                </button>
              </div>
              {isLegendVisible && (
                <div className="legend-scale">
                  {layerInfo[activeLayer].legend.colors.map((color: string, index: number) => (
                    <div key={index} className="legend-item">
                      <span className="legend-color" style={{ backgroundColor: color }}></span>
                      <span className="legend-label">{layerInfo[activeLayer].legend!.labels[index]}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}; 