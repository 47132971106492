import { useAlerts } from '../../context/AlertsContext';
import './WeatherAlerts.scss';

export const WeatherAlerts: React.FC = () => {
  const { alerts } = useAlerts();

  if (!alerts || alerts.length === 0) {
    return null;
  }

  const getSeverityClass = (severity: string) => {
    switch (severity.toLowerCase()) {
      case 'high':
        return 'alert-severe';
      case 'medium':
        return 'alert-warning';
      default:
        return 'alert-info';
    }
  };

  return (
    <div className="weather-alerts">
      {alerts.map((alert) => (
        <div key={`${alert.headline}-${alert.effective}`} className={`alert ${getSeverityClass(alert.severity)}`}>
          <div className="alert-header">
            <span className="alert-icon">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <h3 className="alert-title">{alert.headline}</h3>
          </div>
          
          <div className="alert-content">
            <p className="alert-description">{alert.headline}</p>
            <p className="alert-details">{alert.desc}</p>
            {alert.instruction && (
              <div className="alert-instructions">
                <strong>Udhëzime: </strong>
                {alert.instruction}
              </div>
            )}
            <p className="alert-time">
              Skadon më: {new Date(alert.expires).toLocaleString('sq-AL')}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}; 