import React, { useEffect, useState } from 'react';
import { AstronomyVisualizer } from '../AstronomyVisualizer/AstronomyVisualizer';
import axios from 'axios';

// Base URL for weather icons
const ICONS_BASE_URL = 'https://raw.githubusercontent.com/basmilius/weather-icons/dev/design/fill/final';

interface AstronomyCardProps {
  location: string;
}

interface AstronomyData {
  sunAltitude: number;
  sunAzimuth: number;
  moonAltitude: number;
  moonAzimuth: number;
  moonPhase: string;
  moonIllumination: number;
  sunrise: string;
  sunset: string;
  moonrise: string;
  moonset: string;
}

export const AstronomyCard: React.FC<AstronomyCardProps> = ({ location }) => {
  const [astronomyData, setAstronomyData] = useState<AstronomyData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const parseTime = (timeStr: string) => {
    if (timeStr === 'No moonrise' || timeStr === 'No moonset') {
      return null;
    }
    
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    
    if (period === 'PM' && hours !== 12) {
      date.setHours(hours + 12);
    } else if (period === 'AM' && hours === 12) {
      date.setHours(0);
    } else {
      date.setHours(hours);
    }
    
    date.setMinutes(minutes);
    return date;
  };

  const calculateCelestialPosition = (riseTimeStr: string, setTimeStr: string, currentTime: Date) => {
    const riseTime = parseTime(riseTimeStr);
    const setTime = parseTime(setTimeStr);
    
    // Handle special cases for moon
    if (!riseTime || !setTime) {
      return { altitude: -20, azimuth: 0 }; // Below horizon
    }

    // Adjust times for day wrapping
    if (setTime < riseTime) {
      setTime.setDate(setTime.getDate() + 1);
    }

    // Check if current time is between rise and set
    let isVisible = false;
    if (setTime > riseTime) {
      isVisible = currentTime >= riseTime && currentTime <= setTime;
    } else {
      isVisible = currentTime >= riseTime || currentTime <= setTime;
    }

    if (!isVisible) {
      return { altitude: -20, azimuth: 0 }; // Below horizon
    }

    // Calculate progress through visible arc
    let progress;
    if (currentTime >= riseTime) {
      const arcDuration = setTime.getTime() - riseTime.getTime();
      const timeFromRise = currentTime.getTime() - riseTime.getTime();
      progress = timeFromRise / arcDuration;
    } else {
      const arcDuration = setTime.getTime() - riseTime.getTime() + 24 * 60 * 60 * 1000;
      const timeFromRise = currentTime.getTime() - riseTime.getTime() + 24 * 60 * 60 * 1000;
      progress = timeFromRise / arcDuration;
    }

    // Calculate altitude (max 70° at peak)
    const maxAltitude = 70;
    const altitude = Math.sin(progress * Math.PI) * maxAltitude;

    // Calculate azimuth (90° at rise to 270° at set)
    const azimuth = 90 + (progress * 180);

    return { altitude, azimuth };
  };

  useEffect(() => {
    const fetchAstronomyData = async () => {
      try {
        setLoading(true);
        const apiKey = import.meta.env.VITE_WEATHER_API_KEY;
        const response = await axios.get(
          `https://api.weatherapi.com/v1/astronomy.json?key=${apiKey}&q=${location}`
        );

        const astronomy = response.data.astronomy;
        const currentTime = new Date();

        const sunPosition = calculateCelestialPosition(
          astronomy.astro.sunrise,
          astronomy.astro.sunset,
          currentTime
        );

        const moonPosition = calculateCelestialPosition(
          astronomy.astro.moonrise,
          astronomy.astro.moonset,
          currentTime
        );

        setAstronomyData({
          sunAltitude: sunPosition.altitude,
          sunAzimuth: sunPosition.azimuth,
          moonAltitude: moonPosition.altitude,
          moonAzimuth: moonPosition.azimuth,
          moonPhase: astronomy.astro.moon_phase,
          moonIllumination: parseInt(astronomy.astro.moon_illumination),
          sunrise: astronomy.astro.sunrise,
          sunset: astronomy.astro.sunset,
          moonrise: astronomy.astro.moonrise,
          moonset: astronomy.astro.moonset
        });

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch astronomy data');
        setLoading(false);
      }
    };

    fetchAstronomyData();
    const interval = setInterval(fetchAstronomyData, 60000);
    return () => clearInterval(interval);
  }, [location]);

  // Get the appropriate moon phase icon
  const getMoonPhaseIcon = (phase: string) => {
    const phaseMap: { [key: string]: string } = {
      'New Moon': 'moon-new',
      'Waxing Crescent': 'moon-waxing-crescent',
      'First Quarter': 'moon-first-quarter',
      'Waxing Gibbous': 'moon-waxing-gibbous',
      'Full Moon': 'moon-full',
      'Waning Gibbous': 'moon-waning-gibbous',
      'Last Quarter': 'moon-last-quarter',
      'Waning Crescent': 'moon-waning-crescent'
    };
    
    const iconName = phaseMap[phase] || 'moon-new';
    return `${ICONS_BASE_URL}/${iconName}.svg`;
  };

  if (loading) {
    return (
      <div className="astronomy-card">
        <div className="loading">Loading astronomy data...</div>
      </div>
    );
  }

  if (error || !astronomyData) {
    return (
      <div className="astronomy-card">
        <div className="error">{error || 'Failed to load astronomy data'}</div>
      </div>
    );
  }

  return (
    <div className="astronomy-card">
      <div className="astronomy-visualization-wrapper">
        <div className="astronomy-visualization">
          <AstronomyVisualizer
            lat={41.3275}
            lon={19.8187}
            date={new Date()}
            astronomyData={astronomyData}
          />
        </div>
      </div>

      <div className="astronomy-info">
        <div className="celestial-data">
          {/* Sun Information */}
          <div className="info-group">
            <div className="info-header">
              <img src={`${ICONS_BASE_URL}/clear-day.svg`} alt="Sun" className="header-icon" />
              <span className="header-text">Dielli</span>
            </div>
            <div className="info-details">
              <div className="time-item">
                <img src={`${ICONS_BASE_URL}/sunrise.svg`} alt="Sunrise" className="info-icon" />
                <span className="info-text">Lindja e diellit: {astronomyData.sunrise}</span>
              </div>
              <div className="time-item">
                <img src={`${ICONS_BASE_URL}/sunset.svg`} alt="Sunset" className="info-icon" />
                <span className="info-text">Perëndimi i diellit: {astronomyData.sunset}</span>
              </div>
            </div>
          </div>

          {/* Moon Information */}
          <div className="info-group">
            <div className="info-header">
              <img src={getMoonPhaseIcon(astronomyData.moonPhase)} alt="Moon" className="header-icon" />
              <span className="header-text">Hëna</span>
            </div>
            <div className="info-details">
              <div className="time-item">
                <img src={`${ICONS_BASE_URL}/moonrise.svg`} alt="Moonrise" className="info-icon" />
                <span className="info-text">Lindja e hënës: {astronomyData.moonrise}</span>
              </div>
              <div className="time-item">
                <img src={`${ICONS_BASE_URL}/moonset.svg`} alt="Moonset" className="info-icon" />
                <span className="info-text">Perëndimi i hënës: {astronomyData.moonset}</span>
              </div>
              <div className="phase-item">
                <img src={getMoonPhaseIcon(astronomyData.moonPhase)} alt="Moon Phase" className="info-icon" />
                <span className="phase-text">Faza: {astronomyData.moonPhase}</span>
                <span className="illumination-text">{astronomyData.moonIllumination}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 