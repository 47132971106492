import React from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';

const RrethNesh: React.FC = () => {
  return (
    <div className="app">
      <Header onSearch={() => {}} />
      
      <main className="main-content about-page">
        <div className="container">
          <div className="content">
            <h1 className="title is-2">Rreth Nesh</h1>
            
            <p className="mb-4">
              Mirë se vini në Moti Kombëtar, aplikacioni për motin i krijuar posaçërisht për shqiptarët në Kosovë, Shqipëri, Maqedoni të Veriut, Mal të Zi dhe Serbi. I zhvilluar nga Postieri.xyz L.L.C., misioni ynë është të ofrojmë informacion të saktë, të përditësuar dhe të lehtë për t'u përdorur për motin, të përshtatur për nevojat e komunitetit shqipfolës.
            </p>

            <h2 className="title is-4">Vizioni Ynë</h2>
            <p className="mb-4">
              Ne synojmë të krijojmë një përvojë të qetë për motin që mban përdoruesit tanë të informuar dhe të përgatitur për çdo ditë, kudo që ndodhen. Qoftë për të planifikuar një udhëtim, për të kontrolluar parashikimin lokal apo për të gjuar informacion mbi shtigje për ecje, Moti Kombëtar është shoqëruesi juaj i besueshëm për motin.
            </p>

            <h2 className="title is-4">Çfarë Ofrojmë</h2>
            <p className="mb-4">
              Moti Kombëtar sjell së bashku burimet më të besueshme të të dhënave për motin, duke përdorur teknologji të avancuar për të siguruar parashikime të sakta dhe në kohë reale. Ne integrojmë API-të nga WeatherAPI.com, OpenWeatherMap dhe Yr.no për të ofruar:
            </p>
            <ul className="mb-4">
              <li>Përditësime të motit aktual për të gjitha qytetet kryesore shqipfolëse.</li>
              <li>Parashikime 24-orëshe për planifikim të detajuar, të përditësuara rregullisht përmes WeatherAPI.</li>
              <li>Parashikime 10-ditore nga Yr.no për informacion më të zgjeruar, me veçori të lehta për zgjerim.</li>
              <li>Animacione të astronomisë dhe pozicionit të diellit për të ndjekur ritmet natyrore të ditës.</li>
              <li>Harta të drejtpërdrejta të motit me shtresa të motit që shfaqin kushtet aktuale në qytetet kryesore, duke përdorur OpenWeatherMap.</li>
            </ul>

            <h2 className="title is-4">Dizajn Minimalist, Përdorshmëri Maksimale</h2>
            <p className="mb-4">
              Dizajni i aplikacionit tonë fokusohet në minimalizëm dhe lehtësi të qasjes. Sa herë që vizitoni Moti Kombëtar, do të gjeni menjëherë informacionin për motin që ju nevojitet, pa elemente të panevojshme. Ne kemi zbatuar parimet e Material Design Lite për të siguruar një ndërfaqe moderne dhe të qetë, të mbështetur nga karta intuitive të motit dhe funksione kërkimi me plotësim automatik.
            </p>

            <h2 className="title is-4">Më Shumë se Thjesht Moti</h2>
            <p className="mb-4">
              Moti Kombëtar nuk është vetëm për motin—platforma jonë shërben gjithashtu si një qendër për lajmet lokale, sportin, historinë e qytetit dhe më shumë. Eksploroni faqet e veçanta për qytetet për informacion mbi destinacionet tuaja të preferuara, të pasuruara me të dhëna dhe përditësime për motin dhe ato që ju interesojnë më shumë.
            </p>

            <h2 className="title is-4">Pse të Zgjidhni Moti Kombëtar?</h2>
            <ul className="mb-4">
              <li>Përshtatur për shqiptarët – I krijuar posaçërisht për komunitetin shqipfolës.</li>
              <li>Parashikime të sakta – Të fuqizuara nga ofruesit kryesorë të të dhënave për motin.</li>
              <li>Përdorim i lehtë – Reklama minimale, navigim i thjeshtë dhe qasje e drejtpërdrejtë në informacion.</li>
            </ul>

            <p className="mb-4">
              Faleminderit që zgjodhët Moti Kombëtar, partnerin tuaj të besuar për gjithçka që lidhet me motin!
            </p>

            <p className="mb-4">
              Qëndroni të lidhur, qëndroni të përgatitur.
            </p>

            <p className="has-text-right">
              <strong>Postieri.xyz L.L.C.</strong>
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default RrethNesh; 