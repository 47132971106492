import React, { useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { weatherapplogo } from '../../assets';
import './Header.scss';
import { Link } from 'react-router-dom';

interface HeaderProps {
  onSearch: (searchTerm: string) => void;
}

interface LocationSuggestion {
  name: string;
  region: string;
  country: string;
}

export const Header: React.FC<HeaderProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const fetchSuggestions = async (query: string) => {
    if (query.length < 2) return;
    
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://api.weatherapi.com/v1/search.json?key=${import.meta.env.VITE_WEATHER_API_KEY}&q=${query}`
      );
      
      if (!response.ok) throw new Error('Failed to fetch suggestions');
      
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetch = debounce(fetchSuggestions, 300);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsSearchActive(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      debouncedFetch.cancel();
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsSearchActive(true);
    
    if (value.length >= 2) {
      debouncedFetch(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      onSearch(searchTerm);
      setIsSearchActive(false);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: LocationSuggestion) => {
    const locationString = suggestion.name;
    setSearchTerm(locationString);
    onSearch(locationString);
    setIsSearchActive(false);
    setSuggestions([]);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-content">
          {/* Logo Section */}
          <div className="logo-section">
            <Link to="/">
              <img src={weatherapplogo} alt="Moti Kombëtar" className="logo" />
            </Link>
            <span className="app-name desktop-only">Moti Kombëtar</span>
          </div>

          {/* Search Section */}
          <div className="search-section" ref={searchRef}>
            <form onSubmit={handleSubmit} className="search-form">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Kërko qytetin..."
                  value={searchTerm}
                  onChange={handleInputChange}
                  onFocus={() => setIsSearchActive(true)}
                />
                <button 
                  type="submit" 
                  className="search-button"
                  aria-label="Kërko"
                >
                  <i className="fas fa-search" aria-hidden="true"></i>
                </button>
              </div>

              {isSearchActive && (
                <div className="search-suggestions">
                  {isLoading ? (
                    <div className="suggestion-loading">
                      <i className="fas fa-spinner fa-spin"></i>
                      Duke kërkuar...
                    </div>
                  ) : suggestions.length > 0 ? (
                    suggestions.map((suggestion) => (
                      <button
                        key={`${suggestion.name}-${suggestion.region}-${suggestion.country}`}
                        type="button"
                        className="suggestion-item"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <i className="fas fa-map-marker-alt"></i>
                        <div className="suggestion-info">
                          <span className="city-name">{suggestion.name}</span>
                          <span className="region-country">
                            {suggestion.region && `${suggestion.region}, `}{suggestion.country}
                          </span>
                        </div>
                      </button>
                    ))
                  ) : searchTerm.length >= 2 ? (
                    <div className="no-suggestions">
                      Nuk u gjet asnjë qytet
                    </div>
                  ) : null}
                </div>
              )}
            </form>
          </div>

          {/* Menu Button */}
          <div className="menu-section" ref={menuRef}>
            <button 
              className="menu-button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Menu"
            >
              <i className="fas fa-bars"></i>
            </button>
            
            {isMenuOpen && (
              <div className="menu-dropdown">
                <Link 
                  to="/rreth-nesh" 
                  className="menu-item"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <i className="fas fa-info-circle"></i>
                  Rreth Nesh
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};