import { useState, useEffect } from 'react';
import { WeatherApiService } from './services/weatherApi';
import { CurrentWeatherCard } from './components/WeatherCards/CurrentWeatherCard';
import { HourlyForecastCard } from './components/WeatherCards/HourlyForecastCard';
import { WeeklyForecastCard } from './components/WeatherCards/WeeklyForecastCard';
import { WeatherMap } from './components/WeatherMap/WeatherMap';
import { AstronomyCard } from './components/WeatherCards/AstronomyCard';
import { WeatherResponse, ForecastResponse, WeatherAlert } from './types/weather';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { WeatherAlerts } from './components/Alerts/WeatherAlerts';
import { useAlerts } from './context/AlertsContext';

interface WeatherData {
  current: WeatherResponse | null;
  forecast: ForecastResponse | null;
}

const WeatherApp: React.FC = () => {
  const [location, setLocation] = useState<string>('Gjakove');
  const [weatherData, setWeatherData] = useState<WeatherData>({ 
    current: null, 
    forecast: null 
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLocating, setIsLocating] = useState(true);
  const { addAlert } = useAlerts();

  const [weatherApi] = useState(() => {
    const apiKey = import.meta.env.VITE_WEATHER_API_KEY;
    if (!apiKey) {
      console.error('Weather API key is not configured');
    }
    return new WeatherApiService(apiKey);
  });

  useEffect(() => {
    const getUserLocation = async () => {
      setIsLocating(true);
      let locationFound = false;

      if ('geolocation' in navigator) {
        try {
          const position = await new Promise<GeolocationPosition>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            });
          });

          const { latitude, longitude } = position.coords;
          setLocation(`${latitude},${longitude}`);
          locationFound = true;
        } catch (err) {
          console.log('Geolocation failed:', err);
        }
      }

      if (!locationFound) {
        try {
          console.log('Attempting IP location...');
          const ipLocation = await weatherApi.getIPLocation();
          console.log('IP Location result:', ipLocation);
          
          if (ipLocation?.location?.name) {
            setLocation(ipLocation.location.name);
            locationFound = true;
          } else {
            console.log('IP location response did not contain location name:', ipLocation);
          }
        } catch (err) {
          console.error('IP location failed:', err);
        }
      }

      if (!locationFound) {
        console.log('All location detection methods failed, using default location');
        setLocation('Gjakove');
      }

      setIsLocating(false);
    };

    getUserLocation();
  }, [weatherApi]);

  const handleSearch = (searchTerm: string) => {
    setLocation(searchTerm);
  };

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const [currentResponse, forecastResponse] = await Promise.all([
          weatherApi.getCurrentWeather(location),
          weatherApi.getForecast(location, 7)
        ]);

        setWeatherData({
          current: currentResponse,
          forecast: forecastResponse
        });

        if (currentResponse.alerts && Array.isArray(currentResponse.alerts)) {
          currentResponse.alerts.forEach((alert: WeatherAlert) => {
            addAlert(alert);
          });
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load weather data');
        console.error('Error fetching weather data:', err);
      } finally {
        setLoading(false);
      }
    };

    if (!isLocating) {
      fetchWeatherData();
    }
  }, [location, isLocating, weatherApi, addAlert]);

  if (isLocating) {
    return (
      <div className="pageloader is-active is-primary">
        <span className="title">Duke gjetur vendndodhjen...</span>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="pageloader">
        <div className="loading-content">
          <div className="spinner"></div>
          <h2 className="title">Duke ngarkuar...</h2>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <div className="notification is-danger">
          <button 
            className="delete" 
            onClick={() => setError(null)}
            aria-label="Close error message"
            title="Close error message"
          ></button>
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <Header onSearch={handleSearch} />
      <WeatherAlerts />
      
      <main className="main-content">
        <div className="weather-grid">
          {weatherData.current && (
            <>
              <section className="current-weather-section">
                <CurrentWeatherCard weatherData={weatherData.current} />
              </section>

              {weatherData.forecast && (
                <>
                  <section className="hourly-forecast-section">
                    <HourlyForecastCard 
                      hourlyData={weatherData.forecast.forecast.forecastday[0].hour} 
                    />
                  </section>

                  <section className="weekly-forecast-section">
                    <WeeklyForecastCard 
                      location={location}
                      weatherApi={weatherApi}
                    />
                  </section>

                  <section className="astronomy-section">
                    <AstronomyCard 
                      location={location}
                    />
                  </section>

                  <section className="map-section">
                    <WeatherMap
                      center={[41.3275, 19.8187]}
                      zoom={7}
                      apiKey={import.meta.env.VITE_OPENWEATHERMAP_API_KEY || ''}
                    />
                  </section>
                </>
              )}
            </>
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default WeatherApp; 