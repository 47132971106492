import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WeatherApp from './WeatherApp';
import RrethNesh from './pages/RrethNesh';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { LanguageProvider } from './context/LanguageContext';
import { AlertsProvider } from './context/AlertsContext';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <LanguageProvider>
        <AlertsProvider>
          <Router>
            <Routes>
              <Route path="/" element={<WeatherApp />} />
              <Route path="/rreth-nesh" element={<RrethNesh />} />
            </Routes>
          </Router>
        </AlertsProvider>
      </LanguageProvider>
    </ErrorBoundary>
  );
};

export default App;