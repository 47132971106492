import { createContext, useContext, useState, ReactNode } from 'react';
import { WeatherAlert } from '../types/weather';

interface AlertsContextType {
  alerts: WeatherAlert[];
  addAlert: (alert: WeatherAlert) => void;
  removeAlert: (id: string) => void;
}

const AlertsContext = createContext<AlertsContextType | undefined>(undefined);

export function AlertsProvider({ children }: { children: ReactNode }) {
  const [alerts, setAlerts] = useState<WeatherAlert[]>([]);

  const addAlert = (alert: WeatherAlert) => {
    setAlerts(prev => [...prev, alert]);
  };

  const removeAlert = (id: string) => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
  };

  return (
    <AlertsContext.Provider value={{ alerts, addAlert, removeAlert }}>
      {children}
    </AlertsContext.Provider>
  );
}

export function useAlerts() {
  const context = useContext(AlertsContext);
  if (context === undefined) {
    throw new Error('useAlerts must be used within an AlertsProvider');
  }
  return context;
} 