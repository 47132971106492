import React from 'react';
import { WeatherResponse } from '../../types/weather';
import { getWeatherIcon } from '../../utils/weatherIcons';
import './CurrentWeatherCard.scss';

interface CurrentWeatherProps {
  weatherData: WeatherResponse;
}

export const CurrentWeatherCard: React.FC<CurrentWeatherProps> = ({
  weatherData
}) => {
  if (!weatherData) return null;

  const { current, location } = weatherData;
  const isDay = new Date().getHours() > 6 && new Date().getHours() < 20;
  const weatherIconUrl = getWeatherIcon(current.condition.code, isDay);

  const getAQIDescription = (aqi: number) => {
    if (aqi <= 50) return 'E mirë';
    if (aqi <= 100) return 'E moderuar';
    if (aqi <= 150) return 'E dëmshme për grupet e ndjeshme';
    if (aqi <= 200) return 'E dëmshme';
    if (aqi <= 300) return 'Shumë e dëmshme';
    return 'E rrezikshme';
  };

  const getAQIColor = (aqi: number) => {
    if (aqi <= 50) return 'has-text-success';
    if (aqi <= 100) return 'has-text-warning';
    if (aqi <= 150) return 'has-text-warning-dark';
    if (aqi <= 200) return 'has-text-danger';
    if (aqi <= 300) return 'has-text-danger-dark';
    return 'has-text-black';
  };

  return (
    <div className="card current-weather">
      <div className="card-content">
        <div className="location-info">
          <div className="location-details">
            <h2 className="title is-4">{location.name}</h2>
            <p className="subtitle is-6">{location.country}</p>
          </div>
        </div>

        <div className="weather-main">
          <div className="columns is-mobile">
            <div className="column is-7">
              <div className="temperature-large">
                {Math.round(current.temp_c)}°
              </div>
              <div className="feels-like">
                <span>Ndihet si {Math.round(current.feelslike_c)}°</span>
              </div>
            </div>
            <div className="column is-5">
              <div className="weather-icon-container">
                <img 
                  src={weatherIconUrl} 
                  alt={current.condition.text}
                  className="weather-icon"
                />
                <div className="condition-text">
                  {current.condition.text}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="weather-details">
          <div className="columns is-mobile is-multiline">
            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-wind"></i>
                </span>
                <div>
                  <strong>{current.wind_kph} km/h</strong>
                  <small>Era</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-compass"></i>
                </span>
                <div>
                  <strong>{current.wind_dir}</strong>
                  <small>Drejtimi</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-tint"></i>
                </span>
                <div>
                  <strong>{current.humidity}%</strong>
                  <small>Lagështia</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-cloud-rain"></i>
                </span>
                <div>
                  <strong>{current.precip_mm} mm</strong>
                  <small>Reshjet</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-sun"></i>
                </span>
                <div>
                  <strong>{current.uv}</strong>
                  <small>Indeksi UV</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-cloud"></i>
                </span>
                <div>
                  <strong>{current.cloud}%</strong>
                  <small>Mbulimi me Re</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-tachometer-alt"></i>
                </span>
                <div>
                  <strong>{current.pressure_mb} mb</strong>
                  <small>Presioni</small>
                </div>
              </div>
            </div>

            <div className="column is-6">
              <div className="detail-item">
                <span className="icon">
                  <i className="fas fa-eye"></i>
                </span>
                <div>
                  <strong>{current.vis_km} km</strong>
                  <small>Dukshmëria</small>
                </div>
              </div>
            </div>

            {current.air_quality && (
              <div className="column is-12">
                <div className="detail-item air-quality">
                  <span className="icon">
                    <i className="fas fa-lungs"></i>
                  </span>
                  <div>
                    <div className="aqi-value">
                      <span className={getAQIColor(current.air_quality['us-epa-index'])}>
                        {getAQIDescription(current.air_quality['us-epa-index'])}
                      </span>
                    </div>
                    <div className="aqi-details">
                      <small>PM2.5: {Math.round(current.air_quality.pm2_5)} • </small>
                      <small>PM10: {Math.round(current.air_quality.pm10)} • </small>
                      <small>O₃: {Math.round(current.air_quality.o3)} • </small>
                      <small>NO₂: {Math.round(current.air_quality.no2)}</small>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};