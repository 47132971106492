import React, { useEffect, useRef } from 'react';
import { drawWeatherEffects } from './utils/weatherEffects';
import './AstronomyVisualizer.scss';

const ICONS_BASE_URL = 'https://raw.githubusercontent.com/basmilius/weather-icons/dev/design/fill/final';

interface AstronomyVisualizerProps {
  lat: number;
  lon: number;
  date: Date;
  astronomyData: {
    sunAltitude: number;
    sunAzimuth: number;
    moonAltitude: number;
    moonAzimuth: number;
    moonPhase: string;
    moonIllumination: number;
    sunrise: string;
    sunset: string;
  };
}

export const AstronomyVisualizer: React.FC<AstronomyVisualizerProps> = ({
  astronomyData
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sunImageRef = useRef<HTMLImageElement | null>(null);
  const moonImageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !astronomyData) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Preload images
    const loadImage = (src: string): Promise<HTMLImageElement> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    // Set canvas size with proper scaling
    const updateCanvasSize = () => {
      const parent = canvas.parentElement;
      if (!parent) return { width: 0, height: 0 };

      const width = parent.offsetWidth;
      const height = parent.offsetHeight;
      
      canvas.width = width * window.devicePixelRatio;
      canvas.height = height * window.devicePixelRatio;
      
      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
      
      return { width, height };
    };

    const drawScene = async () => {
      const { width, height } = updateCanvasSize();

      // Clear canvas
      ctx.clearRect(0, 0, width, height);

      // Determine if it's daytime based on sun altitude
      const isDaytime = astronomyData.sunAltitude > 0;

      // Draw background gradient based on time of day
      const gradient = ctx.createLinearGradient(0, 0, 0, height);
      if (isDaytime) {
        gradient.addColorStop(0, '#4A90E2');  // Sky blue
        gradient.addColorStop(0.7, '#64B5F6'); // Light blue
        gradient.addColorStop(1, '#90CAF9');   // Lighter blue
      } else {
        gradient.addColorStop(0, '#1A237E');   // Deep night blue
        gradient.addColorStop(0.7, '#283593'); // Dark blue
        gradient.addColorStop(1, '#303F9F');   // Slightly lighter blue
      }
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);

      // Draw stars if nighttime
      if (!isDaytime) {
        for (let i = 0; i < 150; i++) {
          const x = Math.random() * width;
          const y = Math.random() * height;
          const size = Math.random() * 1.5;
          const opacity = Math.random() * 0.5 + 0.25;
          
          ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
          ctx.beginPath();
          ctx.arc(x, y, size, 0, Math.PI * 2);
          ctx.fill();
        }
      }

      // Draw horizon line with gradient
      const horizonY = height * 0.7;
      const horizonGradient = ctx.createLinearGradient(0, horizonY - 1, 0, horizonY + 1);
      horizonGradient.addColorStop(0, 'rgba(255, 255, 255, 0.2)');
      horizonGradient.addColorStop(1, 'rgba(255, 255, 255, 0.05)');
      
      ctx.strokeStyle = horizonGradient;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(0, horizonY);
      ctx.lineTo(width, horizonY);
      ctx.stroke();

      // Calculate celestial body positions
      const calculatePosition = (altitude: number, azimuth: number) => {
        // Adjust azimuth to ensure objects don't go off-screen on the right
        const adjustedAzimuth = Math.max(10, Math.min(350, azimuth)); // Keep within 10-350 degree range
        
        // Calculate x position with padding
        const padding = width * 0.1; // 10% padding on each side
        const usableWidth = width - (padding * 2);
        const x = padding + (usableWidth * (adjustedAzimuth / 360));
        
        // Calculate y position with improved altitude scaling
        const maxAltitudePixels = height * 0.6; // Maximum height for altitude
        const y = horizonY - (maxAltitudePixels * (altitude / 90));
        
        return { x, y };
      };

      // Load images if not already loaded
      if (!sunImageRef.current) {
        sunImageRef.current = await loadImage(`${ICONS_BASE_URL}/clear-day.svg`);
      }
      if (!moonImageRef.current) {
        const moonPhaseIcon = getMoonPhaseIcon(astronomyData.moonPhase);
        moonImageRef.current = await loadImage(`${ICONS_BASE_URL}/${moonPhaseIcon}.svg`);
      }

      // Calculate sizes based on screen width - adjusted for mobile
      const baseSize = Math.min(width, height) * (width < 768 ? 0.22 : 0.18); // Slightly smaller on desktop
      const sunSize = baseSize;
      const moonSize = baseSize * 0.95; // Moon slightly smaller than sun

      // Draw sun if it's above horizon
      if (astronomyData.sunAltitude > -2) {
        const sunPos = calculatePosition(astronomyData.sunAltitude, astronomyData.sunAzimuth);
        ctx.save();
        ctx.globalAlpha = 0.95;
        ctx.drawImage(
          sunImageRef.current,
          sunPos.x - sunSize / 2,
          sunPos.y - sunSize / 2,
          sunSize,
          sunSize
        );
        ctx.restore();
      }

      // Draw moon if it's above horizon
      if (astronomyData.moonAltitude > -2) {
        const moonPos = calculatePosition(astronomyData.moonAltitude, astronomyData.moonAzimuth);
        ctx.save();
        ctx.globalAlpha = 0.9;
        ctx.drawImage(
          moonImageRef.current,
          moonPos.x - moonSize / 2,
          moonPos.y - moonSize / 2,
          moonSize,
          moonSize
        );
        ctx.restore();
      }

      // Add subtle weather effects
      drawWeatherEffects(ctx, 0.2, width, height, isDaytime);
    };

    // Initial draw
    drawScene();

    // Handle window resize
    const handleResize = () => {
      drawScene();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [astronomyData]);

  // Helper function to get moon phase icon
  const getMoonPhaseIcon = (phase: string): string => {
    const phaseMap: { [key: string]: string } = {
      'New Moon': 'moon-new',
      'Waxing Crescent': 'moon-waxing-crescent',
      'First Quarter': 'moon-first-quarter',
      'Waxing Gibbous': 'moon-waxing-gibbous',
      'Full Moon': 'moon-full',
      'Waning Gibbous': 'moon-waning-gibbous',
      'Last Quarter': 'moon-last-quarter',
      'Waning Crescent': 'moon-waning-crescent'
    };
    
    return phaseMap[phase] || 'moon-new';
  };

  return (
    <div className="astronomy-visualizer">
      <canvas 
        ref={canvasRef}
        className="astronomy-canvas"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};