const GITHUB_ICONS_BASE = 'https://raw.githubusercontent.com/basmilius/weather-icons/dev/production/fill/svg';

// Map WeatherAPI condition codes to Basmilius icon names
export const getWeatherIcon = (code: number, isDay: boolean = true): string => {
  const iconMap: { [key: number]: string } = {
    200: 'thunderstorms-day-rain',
    201: 'thunderstorms-rain',
    202: 'thunderstorms-extreme-rain',
    210: 'thunderstorms-day',
    211: 'thunderstorms',
    212: 'thunderstorms-extreme',
    221: 'thunderstorms-extreme',
    230: 'thunderstorms-day-rain',
    231: 'thunderstorms-rain',
    232: 'thunderstorms-extreme-rain',
    300: 'drizzle',
    301: 'drizzle',
    302: 'rain',
    310: 'rain',
    311: 'rain',
    312: 'rain-heavy',
    313: 'rain-heavy',
    314: 'rain-heavy',
    321: 'rain',
    500: 'partly-cloudy-day-rain',
    501: 'rain',
    502: 'rain-heavy',
    503: 'rain-heavy',
    504: 'rain-extreme',
    511: 'sleet',
    520: 'partly-cloudy-day-rain',
    521: 'rain',
    522: 'rain-heavy',
    531: 'rain-heavy',
    600: 'snow',
    601: 'snow',
    602: 'snow-heavy',
    611: 'sleet',
    612: 'sleet',
    613: 'sleet',
    615: 'sleet',
    616: 'sleet',
    620: 'snow',
    621: 'snow',
    622: 'snow-heavy',
    701: 'mist',
    711: 'smoke',
    721: 'haze',
    731: 'dust',
    741: 'fog',
    751: 'dust',
    761: 'dust',
    762: 'dust',
    771: 'wind',
    781: 'tornado',
    800: isDay ? 'clear-day' : 'clear-night',
    801: isDay ? 'partly-cloudy-day' : 'partly-cloudy-night',
    802: 'cloudy',
    803: 'overcast',
    804: 'overcast',
    1000: isDay ? 'clear-day' : 'clear-night',
    1003: isDay ? 'partly-cloudy-day' : 'partly-cloudy-night',
    1006: 'cloudy',
    1009: 'overcast',
    1030: 'mist',
    1063: isDay ? 'partly-cloudy-day-rain' : 'partly-cloudy-night-rain',
    1066: isDay ? 'partly-cloudy-day-snow' : 'partly-cloudy-night-snow',
    1069: isDay ? 'partly-cloudy-day-sleet' : 'partly-cloudy-night-sleet',
    1072: 'drizzle',
    1087: isDay ? 'thunderstorms-day' : 'thunderstorms-night',
    1114: 'snow',
    1117: 'snow-heavy',
    1135: 'fog',
    1147: 'fog',
    1150: 'drizzle',
    1153: 'drizzle',
    1168: 'drizzle',
    1171: 'drizzle',
    1180: isDay ? 'partly-cloudy-day-rain' : 'partly-cloudy-night-rain',
    1183: 'rain',
    1186: 'rain',
    1189: 'rain',
    1192: 'rain-heavy',
    1195: 'rain-heavy',
    1198: 'sleet',
    1201: 'sleet',
    1204: 'sleet',
    1207: 'sleet',
    1210: 'snow',
    1213: 'snow',
    1216: 'snow',
    1219: 'snow',
    1222: 'snow-heavy',
    1225: 'snow-heavy',
    1237: 'hail',
    1240: isDay ? 'partly-cloudy-day-rain' : 'partly-cloudy-night-rain',
    1243: 'rain-heavy',
    1246: 'rain-heavy',
    1249: 'sleet',
    1252: 'sleet',
    1255: 'snow',
    1258: 'snow-heavy',
    1261: 'hail',
    1264: 'hail',
    1273: isDay ? 'thunderstorms-day-rain' : 'thunderstorms-night-rain',
    1276: 'thunderstorms-rain',
    1279: isDay ? 'thunderstorms-day-snow' : 'thunderstorms-night-snow',
    1282: 'thunderstorms-snow'
  };

  const iconName = iconMap[code] || 'not-available';
  return `${GITHUB_ICONS_BASE}/${iconName}.svg`;
};

// Map YR.no symbols to Basmilius icons
export const getYrNoIcon = (symbol: string): string => {
  const iconMap: { [key: string]: string } = {
    clearsky_day: 'clear-day.svg',
    clearsky_night: 'clear-night.svg',
    cloudy: 'cloudy.svg',
    fair_day: 'partly-cloudy-day.svg',
    fair_night: 'partly-cloudy-night.svg',
    fog: 'fog.svg',
    heavyrain: 'extreme-rain.svg',
    heavyrainandthunder: 'extreme-thunderstorms.svg',
    heavyrainshowers_day: 'extreme-day-rain.svg',
    heavyrainshowers_night: 'extreme-night-rain.svg',
    heavysleet: 'extreme-sleet.svg',
    heavysleetandthunder: 'extreme-thunderstorms-sleet.svg',
    heavysnow: 'extreme-snow.svg',
    heavysnowandthunder: 'extreme-thunderstorms-snow.svg',
    lightrain: 'rain.svg',
    lightrainandthunder: 'thunderstorms-rain.svg',
    lightrainshowers_day: 'partly-cloudy-day-rain.svg',
    lightrainshowers_night: 'partly-cloudy-night-rain.svg',
    lightsleet: 'sleet.svg',
    lightsnow: 'snow.svg',
    partlycloudy_day: 'partly-cloudy-day.svg',
    partlycloudy_night: 'partly-cloudy-night.svg',
    rain: 'rain.svg',
    rainandthunder: 'thunderstorms-rain.svg',
    rainshowers_day: 'partly-cloudy-day-rain.svg',
    rainshowers_night: 'partly-cloudy-night-rain.svg',
    sleet: 'sleet.svg',
    sleetandthunder: 'thunderstorms-sleet.svg',
    sleetshowers_day: 'partly-cloudy-day-sleet.svg',
    sleetshowers_night: 'partly-cloudy-night-sleet.svg',
    snow: 'snow.svg',
    snowandthunder: 'thunderstorms-snow.svg',
    snowshowers_day: 'partly-cloudy-day-snow.svg',
    snowshowers_night: 'partly-cloudy-night-snow.svg'
  };

  const iconName = iconMap[symbol] || 'not-available.svg';
  return `${GITHUB_ICONS_BASE}/${iconName}`;
}; 