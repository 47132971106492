import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper';
import { getWeatherIcon } from '../../utils/weatherIcons';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

interface HourlyForecastProps {
  hourlyData: any[];
}

export const HourlyForecastCard: React.FC<HourlyForecastProps> = ({ hourlyData }) => {
  const swiperRef = useRef<SwiperType>();

  const formatHour = (time: string) => {
    const hour = new Date(time).getHours();
    const now = new Date().getHours();
    return hour === now ? 'Tani' : `${hour}:00`;
  };

  const isCurrentHour = (time: string) => {
    return new Date(time).getHours() === new Date().getHours();
  };

  const isDay = (hour: number) => hour >= 6 && hour <= 18;

  // Find current hour index
  useEffect(() => {
    const currentHourIndex = hourlyData.findIndex(hour => 
      isCurrentHour(hour.time)
    );
    
    if (currentHourIndex !== -1 && swiperRef.current) {
      // Slide to current hour with a small delay to ensure Swiper is initialized
      setTimeout(() => {
        swiperRef.current?.slideTo(currentHourIndex, 0);
      }, 100);
    }
  }, [hourlyData]);

  return (
    <div className="card hourly-forecast">
      <div className="card-header">
        <h3 className="card-title">
          <span className="icon">
            <i className="fas fa-clock"></i>
          </span>
          Parashikimi 24 Orësh
        </h3>
      </div>
      
      <div className="card-content">
        <Swiper
          modules={[FreeMode, Navigation]}
          slidesPerView="auto"
          spaceBetween={8}
          freeMode={true}
          navigation={true}
          className="hourly-swiper"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          breakpoints={{
            320: { slidesPerView: 3.5, spaceBetween: 8 },
            480: { slidesPerView: 4.5, spaceBetween: 12 },
            768: { slidesPerView: 6.5, spaceBetween: 16 },
            1024: { slidesPerView: 8.5, spaceBetween: 20 },
          }}
        >
          {hourlyData.map((hour, index) => {
            const hourTime = new Date(hour.time);
            const isCurrent = isCurrentHour(hour.time);
            
            return (
              <SwiperSlide key={index} className="hourly-slide">
                <div className={`hour-card ${isCurrent ? 'is-current' : ''}`}>
                  <div className="hour-time">
                    {formatHour(hour.time)}
                  </div>
                  
                  <div className="hour-icon-container">
                    <img 
                      src={getWeatherIcon(hour.condition.code, isDay(hourTime.getHours()))}
                      alt={hour.condition.text}
                      className="hour-icon"
                    />
                  </div>
                  
                  <div className="hour-temp">
                    {Math.round(hour.temp_c)}°
                  </div>
                  
                  <div className="hour-rain">
                    <span className="icon">
                      <i className="fas fa-tint"></i>
                    </span>
                    <span>{hour.chance_of_rain}%</span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}; 