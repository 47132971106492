import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h4 className="title is-6">Rreth Nesh</h4>
            <p>Moti Kombëtar ofron parashikime të sakta të motit për të gjitha qytetet e Shqipërisë.</p>
          </div>
          <div className="column">
            <h4 className="title is-6">Kontakt</h4>
            <p>
              <a href="mailto:info@motikombëtar.al">info@motikombëtar.al</a>
            </p>
          </div>
          <div className="column">
            <h4 className="title is-6">Ndiqni</h4>
            <div className="social-links">
              <a href="#" className="mr-3"><i className="fab fa-facebook"></i></a>
              <a href="#" className="mr-3"><i className="fab fa-twitter"></i></a>
              <a href="#" className="mr-3"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
        <div className="copyright mt-4">
          <p>© {new Date().getFullYear()} Moti Kombëtar. Të gjitha të drejtat e rezervuara.</p>
        </div>
      </div>
    </footer>
  );
}; 