import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { sq } from 'date-fns/locale';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import { getYrNoIcon } from '../../utils/weatherIcons';
import { WeatherApiService } from '../../services/weatherApi';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

interface WeatherDay {
  date: string;
  maxTemp: number;
  minTemp: number;
  condition: {
    text: string;
    icon: string;
    code: number;
  };
  chanceOfRain: number;
  source: 'weatherapi' | 'yrno';
}

interface WeeklyForecastProps {
  location: string;
  weatherApi: WeatherApiService;
}

export const WeeklyForecastCard: React.FC<WeeklyForecastProps> = ({
  location,
  weatherApi
}) => {
  const [forecastData, setForecastData] = useState<WeatherDay[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const currentWeather = await weatherApi.getCurrentWeather(location);
        const { lat, lon } = currentWeather.location;
        await fetchAllWeatherData(lat, lon);
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };
    
    fetchLocation();
  }, [location]);

  const fetchAllWeatherData = async (lat: number, lon: number) => {
    setLoading(true);
    try {
      const yrnoForecast = await weatherApi.getYrNoForecast(lat, lon);
      const futureDays = processYrNoForecast(yrnoForecast);
      setForecastData(futureDays);
    } catch (error) {
      console.error('Error fetching forecast data:', error);
    } finally {
      setLoading(false);
    }
  };

  const processYrNoForecast = (data: any): WeatherDay[] => {
    try {
      const dailyData: { [key: string]: any[] } = {};
      
      if (!data?.properties?.timeseries) {
        console.error('Invalid YR.no data structure:', data);
        return [];
      }
      
      const next14Days = data.properties.timeseries.filter((item: any) => {
        const itemDate = new Date(item.time);
        const today = new Date();
        const diff = Math.floor((itemDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
        return diff >= 0 && diff < 14;
      });

      next14Days.forEach((item: any) => {
        const date = item.time.split('T')[0];
        if (!dailyData[date]) {
          dailyData[date] = [];
        }
        dailyData[date].push(item);
      });

      return Object.entries(dailyData).map(([date, items]) => {
        const temps = items.map(item => 
          item.data?.instant?.details?.air_temperature ?? 0
        );
        const maxTemp = Math.max(...temps);
        const minTemp = Math.min(...temps);
        
        const daySymbols = items
          .filter(item => {
            const hour = new Date(item.time).getHours();
            return hour >= 6 && hour <= 18;
          })
          .map(item => item.data?.next_6_hours?.summary?.symbol_code || 
                      item.data?.next_1_hours?.summary?.symbol_code)
          .filter(Boolean);

        const mostFrequentSymbol = daySymbols.length > 0 
          ? daySymbols.reduce(
              (a, b) => (daySymbols.filter(v => v === a).length >= daySymbols.filter(v => v === b).length ? a : b),
              daySymbols[0]
            )
          : 'cloudy';

        const precipProbs = items
          .map(item => 
            item.data?.next_6_hours?.details?.probability_of_precipitation || 
            item.data?.next_1_hours?.details?.probability_of_precipitation || 
            0
          );
        const avgPrecipProb = precipProbs.reduce((a, b) => a + b, 0) / precipProbs.length;

        return {
          date,
          maxTemp,
          minTemp,
          condition: {
            text: mostFrequentSymbol.replace(/_/g, ' ').toUpperCase(),
            icon: getYrNoIcon(mostFrequentSymbol),
            code: 0
          },
          chanceOfRain: avgPrecipProb,
          source: 'yrno' as const
        };
      });
    } catch (error) {
      console.error('Error processing YR.no forecast:', error);
      return [];
    }
  };

  const formatDay = (date: string) => {
    const forecastDate = new Date(date);
    const today = new Date();
    
    if (forecastDate.toDateString() === today.toDateString()) {
      return 'Sot';
    }
    if (forecastDate.toDateString() === new Date(today.setDate(today.getDate() + 1)).toDateString()) {
      return 'Nesër';
    }
    return format(forecastDate, 'EEEE', { locale: sq });
  };

  if (loading) {
    return (
      <div className="card weekly-forecast">
        <div className="card-content has-text-centered">
          <span className="icon is-large">
            <i className="fas fa-spinner fa-pulse fa-2x"></i>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="card weekly-forecast">
      <div className="card-header">
        <h3 className="card-title">
          <span className="icon">
            <i className="fas fa-calendar-week"></i>
          </span>
          Parashikimi 14 Ditor
        </h3>
      </div>
      
      <div className="card-content">
        <Swiper
          modules={[FreeMode, Navigation]}
          slidesPerView="auto"
          spaceBetween={8}
          freeMode={true}
          navigation={true}
          className="weekly-swiper"
          breakpoints={{
            320: { slidesPerView: 2.5, spaceBetween: 8 },
            480: { slidesPerView: 3.5, spaceBetween: 12 },
            768: { slidesPerView: 4.5, spaceBetween: 16 },
            1024: { slidesPerView: 7, spaceBetween: 20 }
          }}
        >
          {forecastData.map((day, index) => (
            <SwiperSlide key={day.date} className="weekly-slide">
              <div className={`forecast-day ${index === 0 ? 'is-today' : ''}`}>
                <div className="day-info">
                  <div className="day-name">
                    <span className="has-text-weight-bold">
                      {formatDay(day.date)}
                    </span>
                    <span className="has-text-grey">
                      {format(new Date(day.date), 'd MMM', { locale: sq })}
                    </span>
                  </div>
                  
                  <div className="weather-info">
                    <div className="weather-icon">
                      <img 
                        src={day.condition.icon} 
                        alt={day.condition.text}
                        loading="lazy"
                      />
                    </div>
                    
                    <div className="temperature-range">
                      <span className="max-temp">
                        <i className="fas fa-temperature-high has-text-danger"></i>
                        {Math.round(day.maxTemp)}°
                      </span>
                      <span className="min-temp">
                        <i className="fas fa-temperature-low has-text-info"></i>
                        {Math.round(day.minTemp)}°
                      </span>
                    </div>
                    
                    <div className="precipitation">
                      <span className="icon has-text-info">
                        <i className="fas fa-tint"></i>
                      </span>
                      <span>{Math.round(day.chanceOfRain)}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}; 