const getBaseUrl = () => {
    return import.meta.env.PROD ? 'https://api.weatherapi.com/v1' : 'https://api.weatherapi.com/v1';
};

const getEnvVar = (key: string) => {
    const value = import.meta.env[key];
    if (!value) {
        console.warn(`Environment variable ${key} is not set!`);
        // Return default values from .env.example for development
        if (key === 'VITE_WEATHER_API_KEY') return 'e4c664c503ec45eea43211509240809';
        if (key === 'VITE_OPENWEATHERMAP_API_KEY') return '18bd6dbbdb8d1e226f32567f8596787c';
        return '';
    }
    return value;
};

export const API_CONFIG = {
    baseUrl: getBaseUrl(),
    weatherApiKey: getEnvVar('VITE_WEATHER_API_KEY'),
    openWeatherMapApiKey: getEnvVar('VITE_OPENWEATHERMAP_API_KEY')
};

// Log configuration status
console.log('API Configuration:', {
    baseUrl: API_CONFIG.baseUrl,
    weatherApiKey: API_CONFIG.weatherApiKey ? '[SET]' : '[NOT SET]',
    openWeatherMapApiKey: API_CONFIG.openWeatherMapApiKey ? '[SET]' : '[NOT SET]'
}); 